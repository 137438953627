<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-3">
          <div class="form-group row">
            <div class="col-lg-10">
              <div class="input-group">
                <span class="input-group-prepend">
                  <span class="input-group-text"><i class="icon-search4"></i></span>
                </span>
                <input type="text" class="form-control" placeholder="Search">
              </div>
            </div>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group row">
            <label class="col-form-label col-lg-4">Filter Date</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-5">
                  <datepicker v-model="picked" class="form-control" input-format="dd-MM-yyyy" />
                </div>
                <div class="col-1 vertical-center justify-content-center">-</div>
                <div class="col-5">
                  <datepicker v-model="picked" class="form-control" input-format="dd-MM-yyyy" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group row">
            <select class="form-control">
              <option>Sort By</option>
              <option value="Ref">Ref</option>
              <option value="statut">Statut</option>
              <option value="client_name">Customer</option>
              <option value="GrandTotal">Grand Total</option>
              <option value="paid_amount">Paid Amount</option>
              <option value="due">Due</option>
              <option value="payment_status">Payment Status</option>
            </select>
          </div>
        </div>
        <div class="col-3">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-light active">
              <input type="radio" value="asc"> A-Z
            </label>
            <label class="btn btn-light">
              <input type="radio" value="desc"> Z-A
            </label>
          </div>
          <button class="btn btn-primary mx-2 text-capitalize"><i class="icon-search4"></i> Search</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <table class="table">
            <thead>
              <tr>
                <th>Ref</th>
                <th>Statut</th>
                <th>Customer</th>
                <th>Grand Total</th>
                <th>Paid Amount</th>
                <th>Due</th>
                <th>Payment Status</th>
              </tr>
            </thead>
            <!-- <tbody v-if="rows.length">
              <tr v-for="(row, index) in rows" :key="index">
                <td>{{ row.Ref }}</td>
                <td class="text-capitalize">{{ row.statut }}</td>
                <td>{{ row.client_name }}</td>
                <td>{{ formatMoney(row.GrandTotal) }}</td>
                <td>{{ formatMoney(row.paid_amount) }}</td>
                <td>{{ formatMoney(row.due) }}</td>
                <td class="text-capitalize">{{ row.payment_status }}</td>
              </tr>
            </tbody> -->
            <tbody>
              <tr>
                <td colspan="5" class="text-center">No data</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"
import Datepicker from 'vue3-datepicker'

export default defineComponent({
  name: 'Expense',
  components: {
    Datepicker
  },
  setup(){
    const picked = ref(new Date())

    return {
      picked
    }
  }
})
</script>

<style>

</style>